import React, { Component } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, useIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { isMainSearchTypeKeywords } from '../../util/search';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import { Heading, Page, LayoutSingleColumn, NamedRedirect } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import css from './PassportRequiredPage.module.css';

export class PassportRequiredPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      scrollingDisabled,
      currentUser,
    } = this.props;

    const title = intl.formatMessage({
      id: 'PassportRequiredPage.title',
    });
    const isVerified = currentUser.attributes.permissions.initiateTransactions == "permission/allow" && currentUser.attributes.permissions.postListings == "permission/allow"

    if (isVerified) {
      return <NamedRedirect name='LandingPage' />
    }

    const passportUploadLink = "https://www.google.com"

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <div className={css.root}>
            <div className={css.content}>
              {/* <div className={css.number}>{title}</div> */}
              <Heading as="h1" rootClassName={css.heading}>
                <FormattedMessage id="PassportRequiredPage.heading" />
              </Heading>
              <p className={css.description}>
                <FormattedMessage id="PassportRequiredPage.description" values={{ passportUploadLink }} />
              </p>
            </div>
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

PassportRequiredPageComponent.defaultProps = {
  staticContext: {},
};

PassportRequiredPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  marketplaceName: string.isRequired,
  isKeywordSearch: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const EnhancedPassportRequiredPage = props => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const history = useHistory();
  const intl = useIntl();

  return (
    <PassportRequiredPageComponent
      routeConfiguration={routeConfiguration}
      marketplaceName={config.marketplaceName}
      isKeywordSearch={isMainSearchTypeKeywords(config)}
      history={history}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser: state.user.currentUser
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PassportRequiredPage = compose(connect(mapStateToProps))(EnhancedPassportRequiredPage);

export default PassportRequiredPage;

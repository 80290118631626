import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './CustomLandingPage.module.css';
import classNames from 'classnames';

export const CompanyKey = props => {
  const { title } = props;
  return (
    <div className={css.companyKeyContent}>
      <div className={css.companyKeyTitle}>
        <FormattedMessage id={title} />
      </div>
    </div>
  );
};

export const SectionCompany = props => {
  return (
    <div style={{ backgroundColor: '#F6F6F6', borderBottom: '1px solid #D9D9D9' }}>
      <div className={css.textContent}>
        <div className={css.sectionCompanyContent}>
          <div className={css.sectionCompanyLeftContent}>
            <div className={css.titleContent}>
              <FormattedMessage
                id="CustomLandingPage.titleContent2"
                values={{
                  boldtext: <span style={{ fontWeight: 'bold' }}>Parnexus?</span>,
                }}
              />
            </div>
            <div>
              <FormattedMessage id="CustomLandingPage.subContent2" />
            </div>
          </div>
          <a className={classNames(css.normalCtaButton, css.desktopOnly)} href={'/s'}>
            <FormattedMessage id="CustomLandingPage.content2Button" />
          </a>
        </div>
      </div>
      <div className={classNames(css.flexWithWrap, css.paddingContent)}>
        <CompanyKey title="CustomLandingPage.companykey1" />
        <CompanyKey title="CustomLandingPage.companykey2" />
        <CompanyKey title="CustomLandingPage.companykey3" />
        <CompanyKey title="CustomLandingPage.companykey4" />
        <CompanyKey title="CustomLandingPage.companykey5" />
        <CompanyKey title="CustomLandingPage.companykey6" />
        <CompanyKey title="CustomLandingPage.companykey7" />
        <CompanyKey title="CustomLandingPage.companykey8" />
        <a className={classNames(css.normalCtaButton, css.mobileOnly)} href={'/s'}>
            <FormattedMessage id="CustomLandingPage.content2Button" />
          </a>
      </div>
    
    </div>
  );
};

export default SectionCompany;

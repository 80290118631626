import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './CustomLandingPage.module.css';

export const SectionMembership = props => {
  const { className, classNameContent, title, sub, buttonText,classNameButton,href } = props;
  return (
    <div className={className}>
      <div className={classNameContent}>
        <div className={css.membershipTitle}>
          <FormattedMessage id={title} />
        </div>
        <div className={css.membershipSub}>
          <FormattedMessage id={sub} />
        </div>
        <a href={href} className={classNameButton}>
          <FormattedMessage id={buttonText} />
        </a>
      </div>
    </div>
  );
};

export default SectionMembership;

import React, { useState, useEffect, memo, useCallback } from 'react';
import { HorizontalTicker } from 'react-infinite-ticker';
import css from './SectionWeeklyEventActivity.module.css';
import { getWeeklyEventActivities } from '../../util/api';
import { formatDistance } from 'date-fns';
import IconInfo from '../IconInfo/IconInfo';

const NO_OF_ACTIVITY_TO_SHOW = 20;

// Memoize the EventActivity component
const EventActivity = memo(({ activityType, createdAt }) => {
  const eventDate = formatDistance(createdAt, new Date(), { addSuffix: true });

  return (
    <div className={css.eventActivity}>
      <h3 className={css.activityType}>{activityType}</h3>
      <p className={css.activityDate}>{eventDate}</p>
    </div>
  );
});

// Helper function to prepare activities
const prepareActivities = (originalActivities, targetCount) => {
  if (originalActivities.length === 0) return [];

  let activities = [...originalActivities];
  if (activities.length > targetCount) {
    return activities.slice(0, targetCount);
  }

  // More efficient duplication logic
  const multiplier = Math.ceil(targetCount / activities.length);
  activities = Array(multiplier)
    .fill(activities)
    .flat()
    .slice(0, targetCount);

  return activities.map((activity, index) => ({
    ...activity,
    key: `${activity.activityType}-${activity.createdAt}-${index}`,
  }));
};

const SectionWeeklyEventActivity = () => {
  const [eventActivities, setEventActivities] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [error, setError] = useState(null);

  const fetchEventActivities = useCallback(async () => {
    try {
      const response = await getWeeklyEventActivities();
      if (response?.data && Array.isArray(response.data)) {
        setTotalEvents(response.data.length);
        const prepared = prepareActivities(response.data, NO_OF_ACTIVITY_TO_SHOW);
        setEventActivities(prepared);
      }
    } catch (err) {
      setError(err);
      console.error('Failed to fetch activities:', err);
    }
  }, []);

  useEffect(() => {
    fetchEventActivities();
  }, [fetchEventActivities]);

  if (error) return null;
  if (totalEvents === 0) return null;

  return (
    <div className={css.sectionEventActivity}>
      <div className={css.headerContainer}>
        <div className={css.title}>
          <h5 className={css.sectionHeading}>Weekly Event Activities</h5>
          <a href="/p/activity_ticker_page">
            <IconInfo className={css.iconInfo} />
          </a>
        </div>
        <p className={css.totalEvents}>Total events: {totalEvents}</p>
      </div>
      <div className={css.eventActivities}>
        <HorizontalTicker duration={55000}>
          {eventActivities.map(({ activityType, createdAt, key }) => (
            <EventActivity activityType={activityType} createdAt={createdAt} key={key} />
          ))}
        </HorizontalTicker>
      </div>
    </div>
  );
};

export default memo(SectionWeeklyEventActivity);

import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from 'react-intl';
import { LayoutSingleColumn, Page } from '../../components';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import homePageLogo from '../../assets/homepageLogo.svg';
import SectionWeeklyEventActivity from '../../components/SectionWeeklyEventActivity/SectionWeeklyEventActivity';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './CustomLandingPage.module.css';
import SectionCompany from './SectionCompany';
import SectionMembership from './SectionMembership';
import SectionSearch from './SectionSearch';
import SectionLatestSearchActivity from '../../components/SectionLatestSearchActivity/SectionLatestSearchActivity';

export const CustomLandingPageComponent = props => {
  const { scrollingDisabled, onManageDisableScrolling } = props;
  const config = useConfiguration();
  const route = useRouteConfiguration();
  const history = useHistory();

  return (
    <Page scrollingDisabled={scrollingDisabled} className={css.entirePage}>
      <LayoutSingleColumn
        topbar={<TopbarContainer isHomePage={true} />}
        footer={<FooterContainer />}
      >

        <div>
          <div className={css.sectionFirst}>
            <div className={css.homePageTopbarContent}>

              <img src={homePageLogo} className={css.mobileImage} />
              <div className={css.topbarSub}>
                <div className={css.mobileDisplay}>
                  <FormattedMessage id="Topbar.topbarSubMobile" />
                </div>
                <div className={css.desktopDisplay}>
                  <FormattedMessage id="Topbar.topbarSub" /><br />
                  <FormattedMessage id="Topbar.topbarSub2" />
                </div>
              </div>
              <a href="/s" className={css.customCtaButton}>
                <FormattedMessage id="Topbar.topbarButton" />
              </a>

            </div>
          </div>

          <div className={classNames(css.customLandingWeeklyActivity)}>
            <SectionLatestSearchActivity key={'weekly-event-activity'} />
          </div> 
          <SectionSearch />
          <SectionCompany />
          <SectionMembership
            className={css.membershipBack}
            classNameContent={css.membershipContent}
            buttonText="CustomLandingPage.membershipButton"
            sub="CustomLandingPage.membershipSub"
            title="CustomLandingPage.membershipTitle"
            classNameButton={css.customCtaButton}
            href="/p/contact"
          />
          <SectionMembership
            className={css.asset}
            classNameContent={css.assetContent}
            buttonText="CustomLandingPage.assetButton"
            sub="CustomLandingPage.assetSub"
            title="CustomLandingPage.assetTitle"
            classNameButton={css.normalCtaButton}
            href="/p/asset-finance"
          />
          <div className={classNames(css.customLandingWeeklyActivity)}>
            <SectionWeeklyEventActivity key={'weekly-event-activity'} />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

CustomLandingPageComponent.propTypes = {};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CustomLandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CustomLandingPageComponent);

export default CustomLandingPage;

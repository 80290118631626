import React, { useState, useEffect, memo, useCallback } from 'react';
import { HorizontalTicker } from 'react-infinite-ticker';

import css from './SectionLatestSearchActivity.module.css';
import { getLatestSearch } from '../../util/api';

import { formatDistance } from 'date-fns';

const NO_OF_ACTIVITY_TO_SHOW = 20;
const shortenString = (str, max) => (str.length > max ? `${str.substring(0, max)}...` : str);

// Memoize the EventActivity component
const EventActivity = memo(({ search }) => {
  const eventDate = formatDistance(search?.attributes.createdAt, new Date(), { addSuffix: true });
  const title = search.attributes.title
  const locationPreference = shortenString(search.attributes.publicData.Location_preference, 30);

  const handleClick = useCallback(() => {
    window.open(`/l/search/${search.id.uuid}`, '_blank');
  }, [search.id.uuid]);

  return (
    <div className={css.eventActivity}
      style={title.length > 20 ? { width: '180px' } : {}}
      onClick={handleClick}>
      <div className={css.activityWrapper}>
        <h3 className={css.activityType}>{title}</h3>
        <p className={css.activityDate} style={{ lineHeight: '1.5' }}>
          {locationPreference}
        </p>
        <p className={css.activityDate}>{eventDate}</p>
      </div>
      <span>|</span>
    </div>
  );
});

// Helper function to prepare search activities
const prepareSearchActivities = (originalActivities, targetCount) => {
  if (originalActivities.length === 0) return [];

  let activities = [...originalActivities];
  if (activities.length > targetCount) {
    return activities.slice(0, targetCount);
  }

  // More efficient duplication logic
  const multiplier = Math.ceil(targetCount / activities.length);
  activities = Array(multiplier)
    .fill(activities)
    .flat()
    .slice(0, targetCount);

  return activities.map((activity, index) => ({
    ...activity,
    key: `search-${activity.id.uuid}-${index}`,
  }));
};

const SectionLatestSearchActivity = () => {
  const [eventActivities, setEventActivities] = useState([]);
  const [totalEvents, setTotalEvents] = useState(0);
  const [error, setError] = useState(null);

  const fetchEventActivities = useCallback(async () => {
    try {
      const response = await getLatestSearch();
      if (response?.data && Array.isArray(response.data)) {
        setTotalEvents(response.data.length);
        const prepared = prepareSearchActivities(response.data, NO_OF_ACTIVITY_TO_SHOW);
        setEventActivities(prepared);
      }
    } catch (err) {
      setError(err);
      console.error('Failed to fetch search activities:', err);
    }
  }, []);

  useEffect(() => {
    fetchEventActivities();
  }, [fetchEventActivities]);

  if (error) return null;
  if (totalEvents === 0) return null;

  return (
    <div className={css.sectionEventActivity}>
      <div className={css.headerContainer}>
        <div className={css.title}>
          <h5 className={css.sectionHeading}>Seeking:</h5>
        </div>
      </div>
      <div className={css.eventActivities}>
        <HorizontalTicker duration={55000}>
          {eventActivities.map(search => (
            <EventActivity search={search} key={search.key} />
          ))}
        </HorizontalTicker>
      </div>
    </div>
  );
};

export default memo(SectionLatestSearchActivity);
